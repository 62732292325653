#swipe-animation {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Poppins-Bold;
    width: 40px;
    height: 40px;
    background-color: #f59e00;
    opacity: 0.3;
    border-radius: 50%;
    animation: swipe 3s 2 backwards;
}

#swipe-animation::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 20px;
    height: 20px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: translateY(-50%) rotate(45deg);
}

@keyframes swipe {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100px);
    }

}

.hidden {
    display: none;
}
