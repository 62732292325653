

.swiper {
    width: 100%;
    height: 100%;
}
/*.swiper-slide img {
    display: block;
    width: 100%;
    height: 20%;
    object-fit: cover;
}

.swiper-slide {
    width: 300px;

}*/

