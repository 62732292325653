*::before,
*::after,
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {

    height: 100%;
    overscroll-behavior: none;
}


#root {
    height: 100%;

}

body {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

marktest {

    background-color: rgba(208, 164, 218, 0.38);

    line-height: 0em;
    padding-bottom: 0em;
}

markx {
    background: rgb(99,10,122); /* Replace these colors with your desired gradient */
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

mark {
    background: linear-gradient(to right, #0A4E8D, #9015B0, #FF8C05); /* Replace these colors with your desired gradient */
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}


underline {
background-color: transparent;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(177, 141, 185, 0.46) 0%);

    -webkit-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
}


/*Fonts*/

@font-face {
    font-family: 'Poppins-ExtraBold';
    font-style: normal;
    font-display: swap;
    src: url('assets/font/poppins/Poppins-ExtraBold.ttf') format('truetype');
}


@font-face {
    font-family: 'Poppins-Regular';
    src: url('assets/font/poppins/Poppins-Regular.ttf') format('truetype'),
    url('assets/font/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('assets/font/poppins/Poppins-Bold.ttf') format('truetype'),
    url('assets/font/poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('assets/font/poppins/Poppins-SemiBold.ttf') format('truetype'),
    url('assets/font/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
